<template>
  <div class="app-contianer">
    <div class="page-bg">
      <div class="bg"></div>
    </div>
    <div id="app-content">
      <div class="page-item tab-box">
        <ul>
          <li :class="tabType === item.key ? 'active' : ''" v-for="item in tabList" :key="item.key"
            @click="changeTab(item)">
            <span>{{ item.name }}</span>
          </li>
        </ul>
      </div>
      <div class="samolist page-item" v-for="(item, index) in samplelist" :key="index">
        <div class="listitle">
          <span>试吃编号：{{ item.logId }}</span>
          <span class="title-text mainColor" @click="change(item.logId)">复制</span>
          <div class="fr">
            <img v-if="item.channelOut === 'C003'" src="../../assets/home/mt.png">
            <img v-if="item.channelOut === 'C004'" src="../../assets/home/elm.png">
          </div>
        </div>
        <div class="listext">
          <div class="listimg">
            <img :src="item.albumPics" style="" />
          </div>
          <div class="list-info">
            <div class="spuName">{{ item.spuName }}</div>
            <div class="shopName">{{ item.shopName }}</div>
            <div class="noPlus f32">满￥{{ item.amount || 0 }} 返￥{{ item.realProfit || 0 }} </div>
          </div>
          <div class="listright">
            <div class="listright-type f38">
              <span
                :class="item.status === 'CANCEL' || item.status === 'NOPASS' || item.status === 'REFUSE' ? 'red' : 'green'">
                {{ item.status | statusFilter }}
              </span>
            </div>
          </div>
        </div>

        <!-- 审核中 -->
        <div class="operate verifyIng" v-if="item.status === 'COMMIT'">
          <!-- <span class="red">倒计时{{lasttime}}</span> -->
          <div class="mainBut f36" @click="order(item)">继续上传</div>
        </div>
        <!-- 锁单 -->
        <div class="operate" v-if="item.status === 'LOCK'">
          <div class="mainBut redBut f36" @click="cancel(item)">取消订单</div>
          <span class="margin-lr2 red f36">
            倒计时
            <TimeCountDown :expireTime="item.expireTime" />
          </span>
          <div class="mainBut fr f36" @click="order(item)">上传截图</div>
        </div>
        <!-- 通过 -->
        <div class="operate verify" v-if="item.status === 'PASS' && item.verifyAdvise">
          <div class="advise red">
            <span>原因: {{ item.verifyAdvise }}</span>
          </div>
        </div>
        <!-- 未通过 -->
        <div class="operate verify" v-if="item.status === 'NOPASS'">
          <div class="advise red">
            <span>原因: {{ item.verifyAdvise ? item.verifyAdvise : '--' }}</span>
          </div>
          <span class="mainBut  f38" @click="order(item)">再次上传</span>
        </div>
        <!-- 拒绝 -->
        <div class="operate verify" v-if="item.status === 'REFUSE'">
          <div class="advise red">
            <span>原因: {{ item.verifyAdvise ? item.verifyAdvise : '--' }}</span>
          </div>
        </div>
      </div>
      <div class="loading">{{ loadtext }}</div>
    </div>
  </div>
</template>

<script>
import TimeCountDown from './compents/timeCountDown.vue'
import { getlist } from '../../api'
import { getScroll, getScrolltop } from '../../utils/utils'
import { mapState } from 'vuex'

const statusMap = Object.freeze([
  { type: 'all', name: '全部' },
  { type: 'PASS', name: '已通过' },
  { type: 'NOPASS', name: '未通过' },
  { type: 'REFUSE', name: '已拒绝' },
  { type: 'LOCK', name: '已申请' },
  { type: 'COMMIT', name: '提交成功' },
  { type: 'CANCEL', name: '已取消' },
  { type: 'HANGUP', name: '待审核' },
  { type: 'FAIL', name: '失败' },
])
const tabList = Object.freeze([
  // { type: ['LOCK', 'NOPASS'], name: '待上传', key: 'upload' },
  { type: ['COMMIT', 'HANGUP', 'LOCK', 'NOPASS'], name: '待处理', key: 'audit' },
  { type: ['PASS'], name: '已通过', key: 'pass' },
  { type: ['REFUSE'], name: '已拒绝', key: 'reject' },
  { type: [], name: '全部', key: 'all' },
])
export default {
  name: 'orderList',
  components: { TimeCountDown },
  data() {
    return {
      statusMap: statusMap,
      tabList: tabList,
      tabType: 'audit',
      emptyimg: require('../../assets/user/notlist.png'),
      samplelist: [],
      messagetext: '',
      listsample: {
        trxCode: 'MS107',
        page: 1,
        pageSize: 20,
      },
      flag: false,
      loadtext: '上拉加载',
    }
  },
  computed: {
    ...mapState('user', ['custMsg']),
    statusList() {
      let item = this.tabList.find((v) => v.key === this.tabType)
      return item ? item.type : []
    },
  },
  filters: {
    statusFilter(val) {
      let item = statusMap.find((v) => v.type === val)
      return item ? item.name : val
    },
  },
  created() {
    this.tabType = this.$route.query.tabType || 'audit'
    this.trylist()
  },
  mounted() {
    window.addEventListener('scroll', this.scrollListener)
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollListener)
  },
  methods: {
    // 前往点餐
    order(item) {
      sessionStorage.setItem('shoptext', JSON.stringify(item))
      this.$router.push({
        path: '/screenshot',
      })
    },

    orderUpload(item) {
      sessionStorage.setItem('shoptext', JSON.stringify(item))
      this.$router.push({
        path: '/screenshot',
      })
    },
    changeTab(item) {
      this.tabType = item.key
      this.listsample.page = 1
      this.samplelist = []
      this.trylist()
    },
    // 审核不通过再次上传凭证
    changemyself(item) {
      sessionStorage.setItem('shoptext', JSON.stringify(item))
      this.$router.push({ path: '/screenshot' })
    },
    //获取订单列表
    trylist() {
      this.listsample.statusList = this.statusList
      getlist(this.listsample).then((res) => {
        if (res.rspCode) {
          this.flag = true
          this.loadtext = '没有更多了'
        } else {
          if (res.orderFoodList) {
            if (res.orderFoodList.length < 20) {
              this.loadtext = '没有更多了'
              this.flag = true
            } else {
              this.flag = false
              this.loadtext = '加载中...'
            }
            res.orderFoodList.forEach((item) => {
              this.samplelist.push(item)
            })
          }
        }
        if (res.orderFoodStatusNum) {
          let num = res.orderFoodStatusNum.reduce((prev, item) => {
            return prev + item.num
          }, 0)
          this.$store.commit('user/SET_ORDERNUM', num)
        }
      })
    },
    //复制订单号
    change(val) {
      this.$copyText(val).then((res) => {
        if (res.text) {
          this.$toast.success('复制成功')
        } else {
          this.$toast.fail('复制失败')
        }
      })
    },
    //取消订单
    cancel(item) {
      let params = {
        trxCode: 'MS106',
        openId: this.custMsg.openIdMp,
        logId: item.logId
      }
      getlist(params).then((res) => {
        if (res.rspMsg) {
          this.$toast.fail(res.rspMsg)
        } else {
          this.$toast.success('取消成功')
          this.samplelist = []
          this.trylist()
        }
      })
    },
    // 滚动监听
    scrollListener() {
      //总高度=滚动条滚动的高度+cliebtHeight（要是想不到底部就加载数据可以写成）
      //offsetHeight +clientHeight>=scrollHeight-n (n是任意数字)
      var hegit = getScrolltop()
      var scroll = getScroll()
      if (hegit >= scroll) {
        if (this.flag === false) {
          this.flag = true
          this.listsample.page = this.listsample.page + 1
          this.trylist()
        }
      }
    },
  },
}
</script>
<style lang="less" scoped>
.tab-box {
  background: transparent;
  padding: 0;

  ul {
    display: flex;
    justify-content: space-between;

    li {
      font-size: 3.8vw;
      color: #555;
    }

    li.active {
      font-weight: bold;
      color: #222;
    }
  }
}

.samolist {
  .listitle {
    font-size: 3.4vw;
    color: #999;
    margin-bottom: 2vw;

    .title-text {
      margin-left: 5vw;
    }

    .fr img {
      width: 5vw;
    }
  }

  .listext {
    display: flex;

    .list-info {
      flex: 1;
      margin: 0 3vw;

      .spuName {
        font-size: 4vw;
        font-weight: bold;
        color: #333;
      }

      .shopName {
        font-size: 3.6vw;
        margin: 1vw 0;
      }
    }

    .listimg {
      display: flex;
      align-items: center;

      img {
        width: 18vw;
        height: 18vw;
        object-fit: cover;
        border-radius: 2vw;
      }
    }

    .listright {
      display: flex;
      align-items: flex-end;
    }
  }

  .operate {
    margin-top: 3vw;
    justify-content: space-between;
  }

  .operate.verify {
    margin-top: 3vw;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .advise {
      flex: 1;
      font-size: 3.6vw;
    }
  }

  .operate.verifyIng {
    display: flex;
    justify-content: flex-end;
  }
}

.loading {
  margin: 3vw 0;
  text-align: center;
  font-size: 3.4vw;
  color: #666;
}
</style>
