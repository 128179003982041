<template>
  <span class="timeCountDown red">
    {{lasttime}}
  </span>
</template>

<script>
import { settime } from '@/utils/utils'

export default {
  name: 'timeCountDown',
  props: ['expireTime'],
  data() {
    return {
      lasttime: ''
    }
  },
  created() {
    this.setCountDown()
  },
  methods: {
    // 获取倒计时
    setCountDown() {
      if (this.expireTime) {
        var timegramp = Date.parse(new Date())
        this.lasttime = settime(
          timegramp / 1000,
          this.expireTime / 1000
        )
        if (this.expireTime > timegramp) {
          this.timeOut = setTimeout(this.setCountDown, 1000)
        }
      }
    },
  }
}
</script>

<style lang='scss' scoped>
</style>